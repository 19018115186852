/* eslint-disable react/display-name */
/* eslint-disable security/detect-object-injection */
import React from 'react'
import { CustomPageRender } from '../../core/render-connector'
import fetcher from '../../core/fetcher'
import { getBlocksServerData } from '../tools/joker'
import { isPotentiallyLowEndDevice } from '../tools/infos'
import { TemplateProvider } from '../templateProvider'
import ServerPage from '../../core/components/ServerPage'
import { serviceUnavailable } from '../errorPageRedirects'

const NfaTemplateRender: CustomPageRender = {
  Render: (props) => <ServerPage {...props} />,
  getServerSideProps: async (context, pageProps) => {
    const { req } = context
    const {
      query,
      locale,
      accountId: selectedPocAccount,
      storeId,
      name: pageName,
      configurations: options = {},
    } = pageProps

    const coreQs = {
      ...query,
      locale,
      ...(selectedPocAccount ? { selectedPocAccount } : {}),
      ...(storeId ? { storeId } : {}),
    }

    const {
      processDeferredOnServer = false,
      cachePage = false,
      noAuthentication = true,
    } = options

    // TODO: remove this in the future
    if (
      !noAuthentication &&
      !context.req.headers.cookie?.includes('connect.sid')
    ) {
      const [language, country] = locale.split('_')
      console.info('401: User is authenticated')
      return {
        redirect: {
          permanent: false,
          destination: `/api/auth?p=B2C_1A_SIGNIN_${country}&country=${country}&language=${language}`,
        },
      }
    }

    const template = await new TemplateProvider(fetcher(context)).getTemplate(
      pageName,
      coreQs,
      cachePage as boolean
    )

    if (!template) {
      return serviceUnavailable
    }

    const shouldProcessBlocks =
      typeof processDeferredOnServer === 'string' &&
      processDeferredOnServer.toLowerCase() === 'auto'
        ? isPotentiallyLowEndDevice(req.headers['user-agent'])
        : processDeferredOnServer

    const cachedBlocks = shouldProcessBlocks
      ? await getBlocksServerData(template, context, coreQs, {
          state: false,
          globals: false,
        })
      : {}

    return {
      props: {
        ...pageProps,
        template: template,
        swrCache: cachedBlocks,
      },
    }
  },
}

export default NfaTemplateRender
